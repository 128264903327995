<template>
  <div id="page-user-view" class="p-6">
    <div class="vx-col flex-1" id="account-info-col-1">
      <form>
        <div class="vx-row">
          <div class="vx-col">
            <div class="page-title p-2">
              <div class="vx-col w-full flex items-center data-list-btn-container">
                <div :style="$route.query.tab === tab ? 'background: #1C5BFE; color: white' : ''" class="relative vx-row m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
                  v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab)">
                  <div class="text-sm">{{$t(tab)}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="xl:float-left clearfix xl:w-1/2 mt-4 w-full">
          <download-button class="mr-4 float-left whitespace-nowrap" :selected="selected"></download-button>
          <mark-ready-button class="mr-4 float-left whitespace-nowrap" :selected="selected" @selected="selected = $event" @loadStatements="loadStatements"></mark-ready-button>
        </div>
        <shipblu-table
          :sst="true"
          orders
          multiple
          v-model="selected"
          pagination
          :max-items="maximumItems"
          :data="statements"
          @search="handleSearch"
          @keyup.enter.native="handleSearch"
          :tableLoader="tableLoader"
          search
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
          </div>
          <template slot="thead">
            <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
            <shipblu-th>{{$t('Statement Number')}}</shipblu-th>
            <shipblu-th>{{$t('Payout Method')}}</shipblu-th>
            <shipblu-th>{{$t('Balance Due')}}</shipblu-th>
            <shipblu-th>{{$t('Cash Collection Balance')}}</shipblu-th>
            <shipblu-th>{{$t('Payout')}}</shipblu-th>
            <shipblu-th>{{$t('Status')}}</shipblu-th>
            <shipblu-th>{{$t('Statement PDF')}}</shipblu-th>
            <shipblu-th>{{$t('Actions')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr class="inactive-link" :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
              <shipblu-td :data="data[indextr].merchant">
                {{ data[indextr].merchant ? data[indextr].merchant.name : '' }}
              </shipblu-td>
              
              <shipblu-td :data="data[indextr].number">
                {{ data[indextr].number }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].payout">
                <template v-if="data[indextr].payout_method">
                  <span class="capitalize">Type: {{data[indextr].payout_method.bank ? 'Bank' : 'Wallet'}}</span><br/>
                  <span v-if="data[indextr].payout_method.bank">Name: {{data[indextr].payout_method.bank.name}}</span><br/>
                  <span>Number: {{data[indextr].payout_method.bank ? data[indextr].payout_method.bank.account_number : data[indextr].payout_method.wallet.number}}</span><br/>
                </template>
              </shipblu-td>
              
              <shipblu-td :data="data[indextr].net_service_fees_due">
                {{ data[indextr].v2 ? Number(data[indextr].v2.balance_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_service_fees_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].net_cod_due">
                {{ data[indextr].v2 ? Number(data[indextr].v2.cash_closing_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_cod_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].net_cod_due">
                {{ data[indextr].v2 ? Number(data[indextr].v2.payout).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_cod_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
                <div class="m-auto flex items-center justify-center font-semibold">
                  <div><span class="status-icon mr-1"></span></div>
                  <span class="capitalize">{{ $t(getStatusLabel(data[indextr].v2 ? data[indextr].v2 : data[indextr])) }}</span>
                </div>
              </shipblu-td>

              <shipblu-td :data="data[indextr].pdf_link" class="active-link">
                <a @click="downloadStatement(data[indextr])" target="_blank" class="hover:underline">{{$t('PDF')}}</a> / 
                <a @click="downloadExcelStatement(data[indextr])" target="_blank" class="hover:underline">{{$t('Excel')}}</a> 
              </shipblu-td>
              
              <shipblu-td class="active-link">
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                  <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-more-horizontal"
                    class="mr-2"
                  >
                  </vs-button>
                  <vs-dropdown-menu style="width: 190px">
                    <vs-dropdown-item :disabled="data[indextr].is_default" @click="generatePDF(data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="FilePlusIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Generate PDF')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item :disabled="data[indextr].is_default" @click="newStatement('Edit', data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="EditIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Edit')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item :disabled="data[indextr].is_default" @click="deleteStatement(data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="TrashIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Delete')}}</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
       </form>
    </div>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <add-statement @file_url="file_url = $event" @loadStatements="loadStatements"
    :type="type" :statementModal="statementModal" @statementModal="statementModal = $event" :statement="statement" @addStatement="addStatement"
    @updateStatement="updateStatement"></add-statement>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import i18nData from '../../i18n/i18nData'
import AddStatement from '../admin/merchant-view/AddStatement.vue'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import DownloadButton from './components/DownloadStatements.vue'
import MarkReadyButton from './components/markReadyButton.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      selected: [],
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      editable: false,
      tabs: ['Needs Review', 'All'],
      tabsDic: {'0': 'Needs Review', '1': 'All'},
      tabsNameDic: {'Needs Review': 0, 'All': 1},
      activeTab: 0,
      statements: [],
      deleteData: {},
      statementModal: false,
      type: 'Add',
      statement: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      merchantID: '',
      file_URl: '',
      statementData: {},
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false
    }
  },
  watch: {
    '$route.query.tab' () {
      this.activeTab = this.tabsNameDic[this.$route.query.tab]
      this.currentPage = 1
      this.loadStatements()
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.selected = []
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadStatements()
    },
    'activeTab' () {
      this.statements = []
      this.searchVal = ''
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadStatements()
      this.currentPage = 1
      this.selected = []
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadStatements()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadStatmentsSearch)
    },
    loadStatmentsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadStatements()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    getAccountStatusColor (order) {
      return common.getOrderStatusColor(order.accounting_status)
    },
    changeTabFilter (tab) {
      this.activeTab = this.tabsNameDic[tab]
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.activeTab,
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
    },
    loadStatements () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?${this.activeTab === '0' || this.activeTab === 0 ? 'status=created' : ''}&offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/statements/${query}`, 'get', null, true, 
        (response) => {
          this.statements = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    downloadStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/pdf/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    downloadExcelStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/excel/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    generatePDF (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/pdf/`, 'post', {}, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['New PDF has been generated successfully. Click download!'],
            position: 'top-center'
          })
        }
      )
    },
    newStatement (type, data) {
      this.type = type
      if (type === 'Add') {
        this.statement = {
          number: '',
          net_service_fees_due: '',
          net_cod_due: '',
          status: '',
          accounting_status: '',
          expected_net_cod_deposit_date: '',
          pdf_link: '',
          merchant: '',
          discount: ''
        }
      } else {
        this.statement = data
      }
      this.statementModal = true
    },
    addStatement () {
      const statement = { 
        number: this.statement.number,
        net_service_fees_due: this.statement.net_service_fees_due,
        net_cod_due: this.statement.net_cod_due,
        status: this.statement.status,
        accounting_status: this.statement.accounting_status,
        expected_net_cod_deposit_date: this.statement.expected_net_cod_deposit_date,
        pdf_link: this.file_url,
        merchant: this.statement.merchant
      }
      sendRequest(false, false, this, 'api/v1/billing/statements/', 'post', statement, true,
        () => {
          this.statementModal = false
          this.loadStatements()
        }
      )
    },
    updateStatement () {
      const statement = { 
        number: this.statement.number,
        net_service_fees_due: this.statement.net_service_fees_due,
        net_cod_due: this.statement.net_cod_due,
        status: this.statement.status,
        accounting_status: this.statement.accounting_status,
        expected_net_cod_deposit_date: this.statement.expected_net_cod_deposit_date,
        pdf_link: this.file_url,
        merchant: this.statement.merchant.id,
        opening_balance: Number(this.statement.opening_balance)
      }
      sendRequest(false, false, this, `api/v1/billing/statements/${this.statement.id}/`, 'patch', statement, true,
        () => {
          this.statementModal = false
          this.loadStatements()
        }
      )
    },
    deleteStatement (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/billing/statements/${this.deleteData.id}/`, 'delete', null, true, 
        () => {
          this.loadStatements()
        }
      )
    }
  },
  components: { 
    AddStatement,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    DownloadButton,
    MarkReadyButton,
    ShipbluPagination
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStatements()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
